body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

  
.navBar{
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}


/* Navigation bar end*/

.styleSearch {
  padding: 10px;
  margin: 10px
}

.styleCheckBoxContainer{
  padding: 5px;
  margin: 10px
}
.Content{
  text-align: center;
}
    .Pagination {
        display: inline-block;
        text-align: center;
        background-color: aqua;
    }

    .Pagination a {
        color: black;
        float: left;
        padding: 8px 16px;
        text-decoration: none;
        transition: background-color .3s;
        border: 1px solid #ddd;
        margin: 0 4px;
    }

    .Pagination a.active {
        background-color: #4CAF50;
        color: white;
        border: 1px solid #4CAF50;
    }

    .Pagination a:hover:not(.active) {
        background-color: #ddd;
    }
