    .Pagination {
        display: inline-block;
        text-align: center;
        background-color: aqua;
    }

    .Pagination a {
        color: black;
        float: left;
        padding: 8px 16px;
        text-decoration: none;
        transition: background-color .3s;
        border: 1px solid #ddd;
        margin: 0 4px;
    }

    .Pagination a.active {
        background-color: #4CAF50;
        color: white;
        border: 1px solid #4CAF50;
    }

    .Pagination a:hover:not(.active) {
        background-color: #ddd;
    }